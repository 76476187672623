import React, { useState } from 'react';
import { Box, Button, TextField, Typography, IconButton, Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CrmService from '@/app/services/api/backend/crm/crmService';
import Logger from '@/app/utils/logger';

interface NewsletterSubscribeProps {
  onClose: () => void;
}

const NewsletterSubscribe: React.FC<NewsletterSubscribeProps> = ({ onClose }) => {
   const [email, setEmail] = useState<string>('');
   const [finalMessage, setFinalMessage] = useState<string>('');
   

   const crmService: CrmService = new CrmService();
 
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      
      try{
        const encodedEmail = encodeURIComponent(email);
        const response = await crmService.getContactByEmail(encodedEmail);
        if(response && response.data.length > 0){
            setFinalMessage('You are already subscribed to GDI Newsletter.')
        }
        else{
          await crmService.createContact(email);
          setFinalMessage('Thank you for subscribing!')
        }
      } catch (error: any) {
        Logger.error(`Error subscribing to HubSpot: ${error?.message}`);
        setFinalMessage('It was not possible to process your subscription now. Please try again later.');
      }
    };

    return (
      <Modal open={true} onClose={onClose}>
        <Box
        component={'div'}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            height: '50vh',
            width: '50vh',
            bgcolor: '#FFC800',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            boxShadow: 24,
            borderRadius: 1,
            p: 4,
          }}
        ><Box
        component={'div'}
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
            }}
          >
            {/* Close Button */}
            <IconButton
              aria-label="close"
              sx={{ position: 'absolute', top: 16, right: 16 }}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
  
            {/* Title */}
            <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2 }}>Fugro</Typography>
            <Typography variant="subtitle1" sx={{ mb: 2 }}> Geo-data Intelligence Newsletter</Typography>
            
            {
              (finalMessage.length == 0)?
              <>
                  <Typography variant="h6" align="center" sx={{ mb: 4 }}>
                    Stay up to date for the latest Geo-data Intelligence news and updates.
                    You can disable anytime.
                  </Typography>
        
                  <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                    <TextField
                      fullWidth
                      label="Email address"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      variant="filled"
                      sx={{ mb: 2, bgcolor: '#fff' }}
                      required
                    />
                    <Button
                      fullWidth
                      type="submit"
                      variant="contained"
                      color="primary"
                      sx={{ bgcolor: '#000', color: '#fff', p: 1.5, fontSize: '16px' }}
                    >
                      Sign Up
                    </Button>
                  </form>
                </>
              :
                <>
                  <Typography variant="h6" align="center" sx={{ fontWeight: 'bold'}}>
                    {finalMessage}
                  </Typography>
                </>
            }
            
          </Box>
        </Box>
      </Modal>
    );
  };
  
  export default NewsletterSubscribe;